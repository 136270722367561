import {Serializable} from '../../core/entities/serializable.model';


export class CarrierModel extends Serializable {
   
    public id: number;
    public carrierType: string;
    public companyName: string;
    public cuit: string;
    public address: string;
    public phone: string;
    public contactName: string;
    public email: string;
    public customBroker: string;
    public urlImage :string;
    public observations: string;
    
   

    newModel(data: any): CarrierModel {
        return new CarrierModel(data);
    }
   

    prepareForStore(): any {
        return {
            carrier_type: this.carrierType,
            company_name: this.companyName,
            cuit: this.cuit,
            address: this.address,
            phone: this.phone,
            contact_name: this.contactName,
            email: this.email,
            custom_broker: this.customBroker,
            url_image: this.urlImage,
            observations: this.observations
            };
    }

     get getTypeCarrier(){

        if(this.carrierType=='exporter'){

            return 'Exportador';
          }else{
            
             return 'Importador';

          }
      

    }
}