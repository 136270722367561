import { Serializable } from "../../../core/entities/serializable.model";
import { OrderTypeModel } from "./order-type.model";
import { CargoTypeModel } from "./cargo-type.model";
import { OrderFollowUpModel } from "./order-follow-up.model";
import { OrderImageModel } from "./order-image.model";
import { OrderDocumentModel } from "./order-document.model";
import { Auth } from "../../../shared/models/auth.model";
import { CountryModel } from "../../../shared/models/country.model";
import { AdminModel } from "../../../shared/models/admin.model";
import { HelperFunctions } from "../../../shared/helpers/functions";
import { UserModel } from "../../../shared/models/user.model";
import { OrderCrossoverModel } from "./order-crossover.model";
import { TruckerHistoryModel } from "./trucker-history.model";
import { CompanyModel } from "app/shared/models/company.model";
import { OrderLocationLogModel } from "./order-location-log.model";
import { BranchModel } from "app/shared/models/branch.model";
import { CrtModel } from "app/main/documents/models/crt.model";
import { OrderLoadModel } from "./order-load.model";
import { CarrierModel } from "app/shared/models/carrier.model";
import { TariffModel } from "app/shared/models/tariff.model";
import { OrderPriceListModel } from "app/shared/models/order-price-list.model";
import { OrderReceptionModel } from "./order-reception.model";

export class OrderModel extends Serializable {
    public id: number;
    public number: number;
    // public company_id: number;
    public details: string;
    public destiny: string;
    public origin: string;
    public fromName: string;
    public addressFrom: string;
    public latitudeFrom: number;
    public longitudeFrom: number;
    public toName: string;
    public addressTo: string;
    public latitudeTo: number;
    public longitudeTo: number;
    public distance: string;
    public currentPercentage: string;
    public notificationEmails: string;
    public status: string;
    public qualification: string;

    /// New field
    public quantity: string;
    public weight: string;
    public volume: string;
    public orderValue: string;
    public orderCoin: string;
    public orderDetail: string;

    //public mic: string;
    public reference: string;
    public billNumber: string;
    public remito: string;
    public nacNumber: string;

    public addressee: string;
    public sender: string;
    // public exporter: string;
    // public importer: string;
    public exporterId: number;
    public importerId: number;
    public pickUpDate: String;
    public coin: string;
    public totalPayment: any;
    public dailyChange: number;

    public billingCompany: string;
    public billingDate: string;
    public billingNumber: string;
    public billingUsd: number;
    public billingArg: number;
    public billingReal: number;
    public hasProblem: number;
    public problemDescription: string;

    public countryFrom: CountryModel;
    public countryTo: CountryModel;

    public crossingRequest: number;
    public crossingDate: string;
    public crossingObs: string;
    public hasTruck: number;

    //public trucker: Auth;
    public company: CompanyModel;
    public crts: CrtModel[];
    // public client: Auth;
    public orderType: OrderTypeModel;
    public cargoType: CargoTypeModel;
    public branch: BranchModel;
    public followUps: OrderFollowUpModel[];
    public images: OrderImageModel[];
    public documents: OrderDocumentModel[];

    public users: UserModel[];
    public orderLoads: OrderLoadModel[];
    public orderCrossovers: OrderCrossoverModel[];
    public orderTruckerHistory: TruckerHistoryModel[];
    public locationLog: OrderLocationLogModel[];
    public exporterData: CarrierModel;
    public importerData: CarrierModel;
    public orderPriceList: OrderPriceListModel;
    public orderReception: OrderReceptionModel;
    public tariff: TariffModel;

    newModel(data: any): OrderModel {
        const dataParsed = new OrderModel(data);

        if (data.company) {
            dataParsed.company = Auth.createOne(
                data.company,
                new CompanyModel()
            );
        }

        if (data.tariff) {
            dataParsed.tariff = TariffModel.createOne(
                data.tariff,
                new TariffModel()
            );
        }

        if (data.orderType) {
            dataParsed.orderType = OrderTypeModel.createOne(
                data.orderType,
                new OrderTypeModel()
            );
        }

        if (data.followUps) {
            dataParsed.followUps = OrderFollowUpModel.createArray(
                data.followUps,
                new OrderFollowUpModel()
            );
            dataParsed.followUps = dataParsed.followUps.sort(
                HelperFunctions.sortDate("date", "asc")
            );
        }

        if (data.cargoType) {
            dataParsed.cargoType = CargoTypeModel.createOne(
                data.cargoType,
                new CargoTypeModel()
            );
        }

        if (data.branch) {
            dataParsed.branch = BranchModel.createOne(
                data.branch,
                new BranchModel()
            );
        }

        if (data.orderLoads) {
            dataParsed.orderLoads = OrderLoadModel.createArray(
                data.orderLoads,
                new OrderLoadModel()
            );
        }

        if (data.crts) {
            dataParsed.crts = CrtModel.createArray(data.crts, new CrtModel());
        }

        if (data.images) {
            dataParsed.images = OrderImageModel.createArray(
                data.images,
                new OrderImageModel()
            );
        }
        if (data.documents) {
            dataParsed.documents = OrderDocumentModel.createArray(
                data.documents,
                new OrderDocumentModel()
            );
        }
        if (data.countryFrom) {
            dataParsed.countryFrom = OrderDocumentModel.createOne(
                data.countryFrom,
                new CountryModel()
            );
        }
        if (data.countryTo) {
            dataParsed.countryTo = OrderDocumentModel.createOne(
                data.countryTo,
                new CountryModel()
            );
        }
        if (data.usersFrontier) {
            dataParsed.users = UserModel.createArray(
                data.usersFrontier,
                new UserModel()
            );
        }

        if (data.exporterData) {
            dataParsed.exporterData = CarrierModel.createOne(
                data.exporterData,
                new CarrierModel()
            );
        }

        if (data.importerData) {
            dataParsed.importerData = CarrierModel.createOne(
                data.importerData,
                new CarrierModel()
            );
        }

        if (data.orderCrossovers) {
            const orderCrossovers = OrderCrossoverModel.createArray(
                data.orderCrossovers,
                new OrderCrossoverModel()
            );
            dataParsed.orderCrossovers = orderCrossovers.sort(
                HelperFunctions.sortDate("dateTime", "desc")
            );
        }

        if (data.locationLog) {
            dataParsed.locationLog = OrderLocationLogModel.createArray(
                data.locationLog,
                new OrderLocationLogModel()
            );
        }

        if (data.orderReception) {
            dataParsed.orderReception = OrderReceptionModel.createOne(
                data.orderReception,
                new OrderReceptionModel()
            );
        }

        if (data.orderPriceList) {
            dataParsed.orderPriceList = OrderPriceListModel.createOne(
                data.orderPriceList,
                new OrderPriceListModel()
            );
        }

        return new OrderModel(dataParsed);
    }

    get percentage(): number {
        return +this.currentPercentage / 100;
    }

    get statusName(): string {
        switch (this.status) {
            case "pending":
                return "Pendiente";
            case "open":
                return "Abierto";
            case "storage":
                return "Depósito";
            case "closed":
                return "Cerrado";
        }
    }

    get nextStatus(): string[] {
        switch (this.status) {
            case "pending":
                return ["open"];
            case "open":
                return ["storage", "closed"];
            case "storage":
                return ["open", "closed"];
            case "closed":
                return ["open"];
        }
    }

    get nextStatusColor(): string {
        switch (this.status) {
            case "pending":
                return "open";
            case "open":
                return "storage";
            case "storage":
                return "closed";
            case "closed":
                return "open";
        }
    }

    get nextStatusNameAction(): string {
        switch (this.status) {
            case "pending":
                return "Abrir";
            case "open":
                return "Depósitar/Cerrar";
            case "storage":
                return "Abrir/Cerrar";
            case "closed":
                return "Abrir";
        }
    }

    get nextStatusName(): string {
        switch (this.status) {
            case "pending":
                return "Abierto";
            case "open":
                return "Depósito/Cerrado";
            case "storage":
                return "Abierto/Cerrado";
            case "closed":
                return "Abierto";
        }
    }

    prepareForStore(): any {
        let data = {
            bill_number: this.billNumber,
            crt: this.crts,
            reference: this.reference,
            company_id: this.company.id,
            order_type_id: this.orderType.id,
            cargo_type_id: this.cargoType.id,
            branch_id: this.branch.id,
            details: this.details,
            address_from: this.addressFrom,
            country_from_id: this.countryFrom.id,
            latitude_from: this.latitudeFrom,
            longitude_from: this.longitudeFrom,
            address_to: this.addressTo,
            country_to_id: this.countryTo.id,
            latitude_to: this.latitudeTo,
            longitude_to: this.longitudeTo,
            distance: this.distance,
            notification_emails: this.notificationEmails,
            users: this.users.map((u) => u.id),
            orderTruckerHistory: this.orderTruckerHistory,
            pick_up_date: this.pickUpDate,
            coin: this.coin,
            total_payment: this.totalPayment,
            daily_change: this.dailyChange,
            quantity: this.quantity,
            weight: this.weight,
            volume: this.volume,
            order_value: this.orderValue,
            order_coin: this.orderCoin,
            order_detail: this.orderDetail,

            billing_company: this.billingCompany,
            billing_date: this.billingDate,
            billing_number: this.billingNumber,
            billing_usd: this.billingUsd,
            billing_arg: this.billingArg,
            billing_real: this.billingReal,

            has_problem: this.hasProblem,
            problem_description: this.problemDescription,
            tariff: this.tariff,

            // crossingRequest: this.crossingRequest,
            // crossingDate: this.crossingDate,
            // crossingObs: this.crossingObs,
            // hasTruck: this.hasTruck,
        };
        if (this.orderType.isInternational) {
            (data["exporter_id"] = this.exporterId),
                (data["importer_id"] = this.importerId);
            // data['exporter'] = this.exporter;
            // data['importer'] = this.importer;
        } else {
            data["remito"] = this.remito;
            data["addressee"] = this.addressee;
            data["sender"] = this.sender;
            data["nac_number"] = this.nacNumber;
        }
        if (this.orderPriceList != null) {
            data["order_price_list"] = this.orderPriceList.prepareForStore();
        }

        return data;
    }
}
